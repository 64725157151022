import { useStaticQuery, graphql } from "gatsby"
import * as React from "react"
import { Contact } from "../../components/contact"
import Footer from "../../components/footer"
import Header from "../../components/header"

export default function Careers () {

    return <>
        <Header />
        <section id="careers" className="mt-32 p-8">
            <div className="text-center text-6xl text-pink-500 mb-4"><strong>Join Us</strong></div>
            <div className="text-center text-2xl text-secondary">Come work with us! Lets change the future of dairy farming</div>
            <div className="mt-8 m-auto w-4/12">
                <div className="text-xl poppins font-bold">Engineering</div>
                <a href="/careers/FSD220320">
                    <div className="bg-gray-200 p-4 mt-2">
                        <div className="text-lg">Senior Full Stack Developer</div>
                        <div className="text-sm">Remote, NZ</div>
                    </div>
                </a>
            </div>
        </section>
        <Contact/>
        <Footer/>
    </>
}